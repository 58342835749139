@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Caladea:ital,wght@0,400;0,700;1,400;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Carme&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');


*[data-theme="light"] {
    --color-primary: #06a0e7;
    --color-primary1: #098cd3;
    --color-primary2: #2fbdff;
    --color-secondary: #F5F5F5;
    --color-secondary2: #E5E5E5;
    --color-secondary3: #D4D4D4;
    --color-neutral: #404040;
    --color-neutral2: #FAFAFA;
}

*[data-theme="dark"] {
    --color-primary: #2abcff;
    --color-primary1: #098cd3;
    --color-primary2: #2fbdff;
    --color-secondary: #322c3b;
    --color-secondary2: #261c29;
    --color-secondary3: #2c2c2c;
    --color-neutral: #FAFAFA;
    --color-neutral2: #404040;
}